import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import "./i18n.js";

if (
  process.env.NODE_ENV === "development" &&
  process.env.REACT_APP_IS_MOCKING === "true"
) {
  require("./mocks/browser");
}

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <React.Fragment>
    <App />
  </React.Fragment>
);
