import chart_rank_up from "assets/icons/chart_rank_up.png";
import chart_rank_down from "assets/icons/chart_rank_down.png";
import chart_rank_new from "assets/icons/chart_rank_new.png";
import chart_rank_hot from "assets/icons/chart_rank_hot.png";
import chart_rank_zero from "assets/icons/chart_rank_zero.png";
import DOMPurify from "dompurify";

/* chart helper functions */

export const rankDiffToIcon = (rankDiff) => {
  /* 
순위 등락의 수치는 표기하지 않는다.
 대신 아이콘화 하며 그 로직과, 우선순위는 다음과 같다.
 1. 대상이 차트에 최초 리스트 업 된 경우 : NEW (발매일과 집계일이 같은 경우)
 2. 수치 절대값 10 미만 (1-9): 상승 or 하락
 3. 수치 절대값 10 이상 (10-99): 급상승 or 급하락
 4. 수치 절대값이 100 이상: HOT (음수는… 차트에서 안보임)
 재진입 로직? yet null
*/
  if (rankDiff === 0) {
    /* 변동 없음 */
    return chart_rank_zero;
  } else if (rankDiff === 9999) {
    /* 상승 */
    return chart_rank_new;
  } else if (0 < rankDiff && rankDiff < 10) {
    /* 상승 */
    return chart_rank_up;
  } else if (10 <= rankDiff && rankDiff < 99) {
    /* 급상승 */
    return chart_rank_hot;
  } else if (100 < rankDiff) {
    /* HOT */
    return chart_rank_hot;
  } else if (-10 < rankDiff && rankDiff < 0) {
    /* 하락 */
    return chart_rank_down;
  } else if (rankDiff <= -10) {
    /* 급하락 */
    return chart_rank_down;
  }
};

export const getArtistSrc = (artistIdx) => {
  const format = "0000" + artistIdx;
  const src = format.slice(format.length - 5);
  const path = `/artist/SH${src}.jpg`;
  return path;
};
export const getAlbumSrc = (albumIdx) => {
  const format = "00000000" + albumIdx;
  const src = format.slice(format.length - 9);
  const path = `/album/${src}_s150.jpg`;
  return path;
};

export const getTargetImg = (targetImg) => {
  const path = `https://resource.hanteochart.io` + targetImg;
  return path;
};

export const getFamilyLogoSrc = (familyCode) => {
  const path = `https://resource.hanteochart.io/family/logo/${familyCode}.jpg`;
  return path;
};

export const getImgSrc = (idx, imgType) => {
  if (imgType === 1) {
    const format = "0000" + idx;
    const src = format.slice(format.length - 5);
    const path = `https://hanteoresource.s3.ap-northeast-2.amazonaws.com/metadata/artist/s150/SH${src}.jpg`;
    return path;
  } else if (imgType === 2) {
    const format = "00000000" + idx;
    const src = format.slice(format.length - 9);
    const path = `https://hanteoresource.s3.ap-northeast-2.amazonaws.com/metadata/album/s150/${src}_s150.jpg`;
    return path;
  } else {
    console.log("idx, imgType 중 필수값이 입력되지 않았습니다.");
  }
};
/* 1 = artist, 2 = album */

export const artistTypeTranslate = (artistType) => {
  if (artistType === "MALE_GROUP") {
    return "남자 그룹";
  } else if (artistType === "FEMALE_GROUP") {
    return "여자 그룹";
  } else if (artistType === "MALE_SOLO") {
    return "남자 솔로";
  } else if (artistType === "FEMALE_SOLO") {
    return "여자 솔로";
  }
};

export const calcRemainingDays = (regDate) => {
  const lastDay = new Date(regDate);
  const today = new Date();

  const differenceInTime = lastDay.getTime() - today.getTime();
  const differenceInDays = Math.floor(differenceInTime / (1000 * 3600 * 24));

  return differenceInDays;
};

export const toFixedIfNecessary = (value) => {
  const decimal = 2;
  /*   return parseFloat(value).toFixed(decimal); */
  return value.toFixed(decimal);
};

export const getEveryNth = (arr, nth) => {
  const result = [];

  for (let i = 0; i < arr.length; i += nth) {
    result.push(arr[i]);
  }

  return result;
};

export const changeBackground = (imgUrl, id) => {
  window.sessionStorage.setItem("backgroundImage", imgUrl);
  if (id) {
    window.sessionStorage.setItem("id", id);
  }
  if (!isEmpty(id)) {
    window.sessionStorage.setItem("backgroundId", id);
  }

  window.dispatchEvent(new CustomEvent("backgroundChange", {}));
  return;
};

export const parseBoardToModule = (data) => {
  if (data) {
    return data.map((obj) => {
      return {
        title: DOMPurify.sanitize(obj.postTitle, { ALLOWED_TAGS: [] }),
        targetIdx: DOMPurify.sanitize(obj.contents?.split("|")[0], {
          ALLOWED_TAGS: [],
        }),
        subInfo: DOMPurify.sanitize(obj.contents?.split("|")[1], {
          ALLOWED_TAGS: [],
        }),
        date: DOMPurify.sanitize(
          obj.createYmdt.slice(0, 10).replaceAll("-", "."),
          { ALLOWED_TAGS: [] }
        ),
      };
    });
  }
};

export const setValueByCondition = (value, detail) => {
  if (typeof value === "string") {
    return value.replace("&#39;", "'");
  } else if (typeof value === "number") {
    if (detail === "album") {
      return value.toLocaleString();
    } else {
      return value.toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
    }
  }
};

export const isEmpty = (value) => {
  if (value === null) return true;
  if (typeof value === "undefined") return true;
  if (typeof value === "string" && value === "") return true;
  if (Array.isArray(value) && value.length < 1) return true;
  if (
    typeof value === "object" &&
    value.constructor.name === "Object" &&
    Object.keys(value).length < 1 &&
    Object.getOwnPropertyNames(value) < 1
  )
    return true;
  if (
    typeof value === "object" &&
    value.constructor.name === "String" &&
    Object.keys(value).length < 1
  )
    return true; // new String()
  if (
    value != null &&
    typeof value == "object" &&
    Object.getPrototypeOf(value) === Map.prototype
  )
    return false;
  return false;
};

export const toggleMobileTooltip = (className) => {
  const mobileTooltip = className
    ? document.querySelector(`.${className}.mobile.tooltip-wrapper`)
    : null;

  if (mobileTooltip) {
    mobileTooltip.classList.toggle("visible");
  }
  return;
};

export const matchArtistType = (type) => {
  switch (type) {
    case "남성그룹":
      return "common.mGroup";
    case "여성그룹":
      return "common.wGroup";
    case "남성솔로":
      return "common.mSolo";
    case "여성솔로":
      return "common.wSolo";
    case "혼성그룹":
      return "common.mixed";
    case "미정의":
      return "common.undefined";
  }
};

export const formatStats = (value) => {
  return value.toLocaleString(undefined, {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
};

export const enableSlices = {
  yearly: false,
  monthly: false,
  weekly: false,
  daily: "x",
  real: "x",
};
/* 주,월,년 툴팁 X (왜지?) */

export function decodeSpecialText(str) {
  if (isEmpty(str)) return "";

  return str
    .replaceAll("&lt;", "<")
    .replaceAll("&gt;", ">")
    .replaceAll("&gt", ">")
    .replaceAll("&apos;", "'")
    .replaceAll("&quot;", '"')
    .replaceAll("&#39;", "'")
    .replaceAll("&amp;", "&")
    .replaceAll("<a", "<a target='_blank' "); //new tab;
  /* .replaceAll("<img", '<img loading="lazy"'); */ //does this actually make any difference? yes it does, but it DOES NOT WORK with accordion. KMN
}

export function brToNewLine(str) {
  if (isEmpty(str)) return "";

  return str
    .replaceAll("<br>", "\n")
    .replaceAll("<br/>", "\n")
    .replaceAll("<br />", "\n");
}

export const sortFamilyTiers = (objs, tier) => {
  if (objs) {
    return objs
      .filter((obj) => {
        return obj["grade"] === tier;
      })
      .sort((a, b) => a.priority - b.priority);
  }
  return;
};

export const smoothlyScrollToTop = () => {
  window.scrollTo({ top: 0, behavior: "smooth" });
};
