import React, { useState } from "react";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { changeBackground } from "helpers";
import bg_image from "assets/backgrounds/background_1920_480_4_1.webp";
import "../layout.css";

function Background({ url, blurred = true, className }) {
  const location = useLocation();
  const [backgroundImg, setbackgroundImg] = useState("");
  const [isBlurred, setIsBlurred] = useState(false);
  const [dropShadow, setDropShadow] = useState(true);

  const getPcBackgroundClass = function (location) {
    let className = "background";
    className = className + (location.pathname === "/about" ? " about" : "");
    className = className + (isBlurred ? " blurred" : "");
    return className;
  };
  const getMoBackgroundClass = function (location) {
    let className = "background";
    className = className + (location.pathname === "/about" ? " about" : "");
    return className;
  };

  useEffect(() => {
    const defaultBG = () => {
      if (!bg_image) {
        setbackgroundImg(bg_image);
      }
    };

    window.addEventListener("firstFetch", defaultBG);

    const changeBG = () => {
      setbackgroundImg(window.sessionStorage.getItem("backgroundImage"));
    };

    window.addEventListener("backgroundChange", changeBG);

    return () => {
      setbackgroundImg(bg_image);
      window.removeEventListener("backgroundChange", changeBG);
      window.removeEventListener("firstFetch", defaultBG);
    };
  }, []);

  useEffect(() => {
    if (location.pathname.indexOf("chart") > -1) {
      setIsBlurred(true);
      setDropShadow(true);
      return;
    }
    if (location.pathname.indexOf("about") > -1) {
      setDropShadow(false);
      return;
    }
    setDropShadow(true);
    setIsBlurred(false);
    changeBackground(bg_image);
  }, [location.pathname]);

  return (
    <React.Fragment>
      <div
        className={getPcBackgroundClass(location)}
        style={{ backgroundImage: `url(${backgroundImg})` }}
      >
        {dropShadow ? <div className="dropshadow" /> : null}
      </div>
      <div className={getMoBackgroundClass(location) + " mobile"}>
        <div className="foreground" style={{ overflow: "hidden" }}>
          <img src={bg_image} alt="" />
        </div>
      </div>
    </React.Fragment>
  );
}

export default Background;
