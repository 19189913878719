import React, { Suspense, lazy } from "react";
import { useMobile } from "MobileContext";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { useMobilePopUp } from "MobilePopupContext";
import LogoSpinner from "components/common/spinner/LogoSpinner";
import Header from "layout/layoutcomponent/Header";
import Footer from "layout/layoutcomponent/Footer";
import MobilePopUp from "layout/layoutcomponent/MobilePopUp";
import MobileNull from "layout/layoutcomponent/MobileNull";
import Background from "layout/layoutcomponent/Background.js";
const Main = lazy(() => import("pages/main/Main.js"));
const Chart = lazy(() => import("pages/charts/Chart.js"));
const Album = lazy(() => import("pages/charts/album/Album.js"));
const AlbumChart = lazy(() => import("pages/charts/album/AlbumChart.js"));
const World = lazy(() => import("pages/charts/world/World.js"));
const Country = lazy(() => import("pages/charts/world/Country.js"));
const WorldChart = lazy(() => import("pages/charts/world/WorldChart.js"));
const Sound = lazy(() => import("pages/charts/sound/Sound.js"));
const SoundChart = lazy(() => import("pages/charts/sound/SoundChart.js"));
const Music = lazy(() => import("pages/charts/music/Music.js"));
const MusicChart = lazy(() => import("pages/charts/music/MusicChart.js"));
const Star = lazy(() => import("pages/charts/star/Star.js"));
const StarChart = lazy(() => import("pages/charts/star/StarChart.js"));
const Social = lazy(() => import("pages/charts/social/Social.js"));
const SocialChart = lazy(() => import("pages/charts/social/SocialChart.js"));
const Authentication = lazy(() =>
  import("pages/charts/authentication/Authentication")
);
const AuthenticationChart = lazy(() =>
  import("pages/charts/authentication/AuthenticationChart")
);
const Genre = lazy(() => import("pages/charts/genre/Genre.js"));
const GenreChart = lazy(() => import("pages/charts/genre/GenreChart.js"));
const Notice = lazy(() => import("pages/board/notice/Notice.js"));
const NewAlbum = lazy(() => import("pages/board/newalbum/NewAlbum.js"));
const ToBeReleased = lazy(() =>
  import("pages/board/tobereleased/ToBeReleased.js")
);
const Family = lazy(() => import("pages/family/Family.js"));
const Search = lazy(() => import("pages/family/search/Search.js"));
const Guide = lazy(() => import("pages/family/guide/Guide.js"));
const Certificate = lazy(() =>
  import("pages/family/certificate/Certificate.js")
);
const ArtistDetail = lazy(() =>
  import("pages/detail/artistdetail/ArtistDetail.js")
);
const ArtistDetailContents = lazy(() =>
  import("pages/detail/artistdetail/ArtistDetailContents.js")
);
const AlbumDetail = lazy(() =>
  import("pages/detail/albumdetail/AlbumDetail.js")
);
const AlbumDetailContents = lazy(() =>
  import("pages/detail/albumdetail/AlbumDetailContents")
);
const SongDetail = lazy(() => import("pages/detail/songdetail/SongDetail.js"));
const SongDetailContents = lazy(() =>
  import("pages/detail/songdetail/SongDetailContents")
);
const Honors = lazy(() => import("pages/honors/Honors.js"));
const Initial = lazy(() => import("pages/honors/initial/Initial.js"));
const About = lazy(() => import("pages/about/About.js"));

export const ChartRouter = () => {
  const isMobile = useMobile();
  const showMobilePopup = useMobilePopUp();
  return (
    <BrowserRouter>
      <Background dropShadow={true} />
      <div className="inner">
        <Header />
        <Suspense fallback={<LogoSpinner />}>
          <Routes>
            <Route path="/" element={<Main />} />

            <Route path="about" element={<About />} />

            <Route path="/chart" element={<Chart />}>
              <Route path="" element={<Navigate replace to="world" />} />
              <Route path="world" element={<World />}>
                <Route path="" element={<Navigate replace to="global" />} />
                <Route path=":country" element={<Country />}>
                  <Route path="" element={<Navigate replace to="weekly" />} />
                  <Route path=":term" element={<WorldChart />} />
                </Route>
              </Route>
              <Route path="album" element={<Album />}>
                <Route path="" element={<Navigate replace to="real" />} />
                <Route path=":term" element={<AlbumChart />} />
              </Route>
              <Route path="digital" element={<Sound />}>
                <Route path="" element={<Navigate replace to="real" />} />
                <Route path=":term" element={<SoundChart />} />
              </Route>
              <Route path="music" element={<Music />}>
                <Route path="" element={<Navigate replace to="real" />} />
                <Route path=":term" element={<MusicChart />} />
              </Route>
              <Route path="authentication" element={<Authentication />}>
                <Route path="" element={<Navigate replace to="real" />} />
                <Route path=":term" element={<AuthenticationChart />} />
              </Route>
              <Route path="star" element={<Star />}>
                <Route path="" element={<Navigate replace to="real" />} />
                <Route path=":term" element={<StarChart />} />
              </Route>
              <Route path="social" element={<Social />}>
                <Route path="" element={<Navigate replace to="weekly" />} />
                <Route path=":term" element={<SocialChart />} />
              </Route>
              <Route path="genre" element={<Genre />}>
                <Route path="" element={<Navigate replace to="weekly" />} />
                <Route path=":term" element={<GenreChart />} />
              </Route>
            </Route>

            <Route
              path="albumdetail/:albumIdx"
              element={isMobile ? <MobileNull /> : <AlbumDetail />}
            >
              <Route path="" element={<Navigate replace to="real" />} />
              <Route path=":term" element={<AlbumDetailContents />} />
            </Route>

            <Route
              path="artistdetail/:artistIdx"
              element={isMobile ? <MobileNull /> : <ArtistDetail />}
            >
              <Route path="" element={<Navigate replace to="real" />} />
              <Route path=":term" element={<ArtistDetailContents />} />
            </Route>

            <Route
              path="songdetail/:songIdx"
              element={isMobile ? <MobileNull /> : <SongDetail />}
            >
              <Route path="" element={<Navigate replace to="real" />} />
              <Route path=":term" element={<SongDetailContents />} />
            </Route>

            <Route path="notice" element={<Notice />} />
            <Route path="newalbum" element={<NewAlbum />} />
            <Route path="tobereleased" element={<ToBeReleased />} />

            <Route path="family" element={<Family />}>
              <Route path="" element={<Navigate replace to="search" />} />
              <Route path="search" element={<Search />} />
              <Route path="guide" element={<Guide />} />
              <Route path="certificate" element={<Certificate />} />
            </Route>

            <Route
              path="honors"
              element={isMobile ? <MobileNull /> : <Honors />}
            >
              <Route path="" element={<Navigate replace to="initial" />} />
              <Route path="initial" element={<Initial />} />
            </Route>

            <Route path="*" element={<>NULL PAGE</>} />
          </Routes>
        </Suspense>
      </div>
      {isMobile && showMobilePopup && <MobilePopUp />}
      <Footer />
    </BrowserRouter>
  );
};
