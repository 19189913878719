export const menus = [
  {
    id: 1,
    className: "home",
    item: "headerNav.home",
    path: "/",
    dep2: null,
    disabled: false,
  },
  {
    id: 2,
    className: "chart",
    item: "headerNav.chart",
    path: "/chart",
    disabled: false,
    dep2: [
      {
        id: 1,
        className: "world",
        item: "chartNav.world",
        path: "/chart/world/global",
        disabled: false,
      },
      {
        id: 2,
        className: "album",
        item: "chartNav.album",
        path: "/chart/album",
        disabled: false,
      },
      {
        id: 3,
        className: "authentication",
        item: "chartNav.global",
        path: "/chart/authentication",
        disabled: false,
      },
      {
        id: 4,
        className: "global",
        item: "chartNav.music",
        path: "/chart/music",
        disabled: false,
      },
      {
        id: 5,
        className: "digital",
        item: "chartNav.sound",
        path: "/chart/digital",
        disabled: false,
      },
      {
        id: 6,
        className: "social",
        item: "chartNav.social",
        path: "/chart/social",
        disabled: false,
      },
      {
        id: 7,
        className: "star",
        item: "chartNav.star",
        path: "/chart/star",
        disabled: false,
      },
    ],
  },
  {
    id: 3,
    className: "hallOfFame",
    item: "headerNav.hallOfFame",
    path: "/honors",
    disabled: true,
    dep2: [
      {
        id: 1,
        className: "initial",
        item: "hallofFameNav.initialChodongRecords",
        path: "/honors/initial",
        disabled: false,
      },
    ],
  },
  {
    id: 4,
    className: "family",
    item: "headerNav.family",
    path: "/family",
    dep2: [
      {
        id: 1,
        className: "search",
        item: "familyNav.certifiedFamilies",
        path: "/family/search",
        disabled: false,
      },
      {
        id: 2,
        className: "guide",
        item: "familyNav.aboutFamilies",
        path: "/family/guide",
        disabled: false,
      },
      {
        id: 3,
        className: "certificate",
        item: "familyNav.certification",
        path: "/family/certificate",
        disabled: false,
      },
    ],
  },
];
