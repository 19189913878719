import React, { useRef, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useMobile } from "MobileContext";
import "../layout.css";
import LangSelect from "./LangSelect";

const Footer = () => {
  const location = useLocation;
  const { t } = useTranslation();
  const ref = useRef();

  const isMobile = useMobile();

  useEffect(() => {}, [location.pathname]);

  return (
    <footer className="footer" ref={ref}>
      <div className="inner">
        <div className="left">
          <div className="row">
            <a href="http://www.hanteoglobal.com/">
              {t("footer.companyIntroduction")}
            </a>
            <a href="https://view.officeapps.live.com/op/view.aspx?src=https://resource.hanteochart.io/qna/%ED%95%9C%ED%84%B0%EC%B0%A8%ED%8A%B8_%EB%AC%B8%EC%9D%98%EC%8B%A0%EC%B2%AD%EC%84%9C.docx">
              {t("footer.partnership")}
            </a>
          </div>
          <div className="row">
            <div className="copyright">{t("footer.copyright")}</div>
          </div>
        </div>
        <div className="right">
          <div className="img-container">
            <img
              className="img"
              src={t("footer.logoImg")}
              alt="저작권 OK 로고"
            />
          </div>
        </div>
        {isMobile ? <LangSelect /> : null}
      </div>
    </footer>
  );
};

export default Footer;
