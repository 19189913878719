import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import mobilePopup from "assets/contents/mobile_popup.png";

function MobileNull() {
  const { t } = useTranslation();

  useEffect(() => {
    const headerTitle = document.querySelector(".mobile-main-title");
    if (headerTitle) {
      headerTitle.style.cssText = `
          display:none`;
    }
    return () => (headerTitle.style.cssText = headerTitle.style.cssText);
  }, []);

  return (
    <div id="mobile-null" className="mobile-null">
      <div className="mobile-null-contents">
        <div className="text-container">
          {t("mobilePopup.serviceInfo")}
          <br />
          <strong>{t("mobilePopup.checkOnPC")}</strong>
        </div>

        <div className="img-container">
          <img className="img" src={mobilePopup} alt="" />
        </div>
        <div className="btn-container">
          <div className="download-app">
            <a href="/">{t("mobilePopup.ok")}</a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MobileNull;
