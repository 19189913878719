import React from "react";
import { ChartRouter } from "./router/ChartRouter";
import { MobileProvider } from "./MobileContext";
import { MobilePopUpProvider } from "./MobilePopupContext";
import "./App.css";
import "layout/mobile.scss";

function App() {
  return (
    <React.Fragment>
      <MobileProvider>
        <MobilePopUpProvider>
          <div className="App" id="App">
            <ChartRouter />
          </div>
        </MobilePopUpProvider>
      </MobileProvider>
    </React.Fragment>
  );
}

export default App;
