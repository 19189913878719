import React from "react";
import { useLocation, NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { toggleMobileTooltip } from "helpers";
import { useMobile } from "MobileContext";
import HeaderNav from "components/nav/HeaderNav.js";

const Header = () => {
  const { t } = useTranslation();
  const isMobile = useMobile();
  const path = useLocation().pathname;

  const noTooltip = ["/notice", "/about"];

  const mobileHeader = (path) => {
    if ("/" === path) {
      // at Main
      return <HeaderNav />;
    } else if ("chart" !== path.split("/")[1]) {
      //somewhere neither Main or Chart
      const getHeaderNameByPath = (path) => {
        let name = "headerMobile.";
        let splitPath = path.split("/");
        if (splitPath.length >= 2) {
          name = name + splitPath[1];
        }
        if (splitPath.length >= 3) {
          name = name + "." + splitPath[2];
        }
        return t(name);
      };
      return (
        <div className="header mobile flex-row">
          <div className="header-nav">
            <NavLink className="go-back" to="/">
              <img
                src="https://resource.hanteochart.io/asset/chart/mo_left.png"
                alt="뒤로가기"
              />
            </NavLink>
            <p className="mobile-main-title">{getHeaderNameByPath(path)}</p>
            {!noTooltip.includes(path) ? (
              <a
                href={null}
                onClick={() =>
                  toggleMobileTooltip(
                    `${path.split("/")[path.split("/").length - 1]}-tooltip`
                  )
                }
              >
                <img
                  src="https://resource.hanteochart.io/asset/chart/mo_info.png"
                  alt="정보"
                />
              </a>
            ) : (
              <a href={null} style={{ visibility: "none" }}></a>
            )}
          </div>
        </div>
      );
    }
  };

  return (
    <React.Fragment>
      {isMobile ? mobileHeader(path) : <HeaderNav />}
    </React.Fragment>
  );
};

export default Header;
