import React from "react";
import "./NavItem.scss";
import { NavLink, useLocation } from "react-router-dom";
import { useMobile } from "MobileContext";
import { useUpdateMobilePopUp } from "MobilePopupContext";

function NavItem({ id, item, path, className, onClickCallback, disabled }) {
  const location = useLocation();
  const isMobile = useMobile();
  const setShowMobilePopup = useUpdateMobilePopUp();

  /* STATS */

  if (isMobile && !disabled) {
    return (
      <li className={className ? `${className} nav-item` : "nav-item"}>
        <NavLink to={path} id={id} onClick={onClickCallback}>
          {item}
        </NavLink>
      </li>
    );
  } else if (isMobile && disabled) {
    return (
      <React.Fragment>
        <li className={className ? `${className} nav-item` : "nav-item"}>
          <a id={id} onClick={isMobile ? () => setShowMobilePopup(true) : null}>
            {item}
          </a>
        </li>
      </React.Fragment>
    );
  }
  return (
    <li className={className ? `${className} nav-item` : "nav-item"}>
      <NavLink
        to={path}
        id={id}
        onClick={onClickCallback}
        className={
          location?.pathname?.includes("/chart/world") &&
          path.includes("/chart/world")
            ? "world-item"
            : ""
        }
      >
        {item}
      </NavLink>
    </li>
  );
}

export default NavItem;
