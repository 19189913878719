import React, { useContext, useState } from "react";

const MobilePopUpContext = React.createContext();
const UpdateMobilePopUpContext = React.createContext();

export function useMobilePopUp() {
  return useContext(MobilePopUpContext);
}
export function useUpdateMobilePopUp() {
  return useContext(UpdateMobilePopUpContext);
}

export function MobilePopUpProvider({ children }) {
  const [showMobilePopup, setShowMobilePopup] = useState(false);
  return (
    <MobilePopUpContext.Provider value={showMobilePopup}>
      <UpdateMobilePopUpContext.Provider value={setShowMobilePopup}>
        {children}
      </UpdateMobilePopUpContext.Provider>
    </MobilePopUpContext.Provider>
  );
}
