import React, { useContext, useEffect, useState } from "react";

const isMobileContext = React.createContext();

export function useMobile() {
  return useContext(isMobileContext);
}

export function MobileProvider({ children }) {
  const [isMobile, setIsMobile] = useState(null);

  const handleResize = () => {
    if (window.innerWidth <= 768) {
      setIsMobile(true);
      return;
    }
    setIsMobile(false);
  };

  useEffect(() => {
    handleResize();
    window.removeEventListener("resize", handleResize);
    window.addEventListener("resize", handleResize);
  }, []);

  return (
    <isMobileContext.Provider value={isMobile}>
      {children}
    </isMobileContext.Provider>
  );
}
