import React from "react";
import "./LogoSpinner.scss";

function LogoSpinner() {
  return (
    <div className="logo-spinner">
      <div className="bar first" />
      <div className="bar second" />
      <div className="bar third" />
      <div className="bar fourth" />
      <div className="bar fifth" />
      <div className="bar sixth" />
    </div>
  );
}

export default LogoSpinner;
