import React, { useEffect } from "react";
import ReactDom from "react-dom";
import "./MobilePopUp.scss";
import mobilePopup from "assets/contents/mobile_popup.png";
import { useTranslation } from "react-i18next";
import { useUpdateMobilePopUp } from "MobilePopupContext";

function MobilePopUp() {
  const { t } = useTranslation();
  const setShowMobilePopup = useUpdateMobilePopUp();
  const toggleClass = () => {
    const mobilePopop = document.querySelector(".mobile-popup-contents");
    const body = document.querySelector(".App");

    mobilePopop.classList.toggle("isUp");
    body.classList.toggle("no-scroll");
    document.body.style.overflow = "hidden";
  };
  const closePopup = () => {
    toggleClass();
    setTimeout(() => {
      setShowMobilePopup(false);
    }, 200);
  };
  useEffect(() => {
    document.body.style.cssText = `
    position: fixed; 
    top: -${window.scrollY}px;
    width: 100%;`;
    return () => {
      const scrollY = document.body.style.top;
      document.body.style.cssText = "";
      window.scrollTo(0, parseInt(scrollY || "0", 10) * -1);
    };
  }, []); /* 배경 스크롤 고정 */

  return ReactDom.createPortal(
    <div id="mobile-popup" className="mobile-popup" onLoad={toggleClass}>
      <div className="mobile-popup-backdrop" onClick={closePopup} />

      <div className="mobile-popup-contents">
        <div className="drag-down-bar" onClick={closePopup} />
        <div className="text-container">
          {t("mobilePopup.serviceInfo")}
          <br />
          <strong>{t("mobilePopup.checkOnPC")}</strong>
        </div>

        <div className="img-container">
          <img className="img" src={mobilePopup} alt="" />
        </div>
        <div className="btn-container">
          <div className="download-app" onClick={closePopup}>
            {t("mobilePopup.ok")}
          </div>
        </div>
      </div>
    </div>,
    document.getElementById("portal")
  );
}

export default MobilePopUp;
