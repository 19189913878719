import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import copyright_logo from "assets/logos/copyright_logo_kr.png";
import copyright_logo_en from "assets/logos/copyright_logo_en.png";
import whosFanEn from "assets/contents/external_service_whosFan_EN.png";
import WhosFanStoreEn from "assets/contents/external_service_store_EN.png";
import HanteoNewsEn from "assets/contents/external_service_news_EN.png";
import whosFanKr from "assets/contents/external_service_whosFan_KR.png";
import WhosFanStoreKr from "assets/contents/external_service_store_KR.png";
import HanteoNewsKr from "assets/contents/external_service_news_KR.png";
import whosFanEs from "assets/contents/external_service_whosFan_ES.png";
import WhosFanStoreEs from "assets/contents/external_service_store_ES.png";
import HanteoNewsEs from "assets/contents/external_service_news_ES.png";
import mainBanner1en from "assets/banners/main_banner_1_en.webp";
import mainBanner1es from "assets/banners/main_banner_1_es.webp";
import mainBanner1ko from "assets/banners/main_banner_1_ko.webp";
import mainBanner2en from "assets/banners/main_banner_2_en.webp";
import mainBanner2es from "assets/banners/main_banner_2_es.webp";
import mainBanner2ko from "assets/banners/main_banner_2_ko.webp";
import mainBanner4 from "assets/banners/main_banner_4.png";
import mainBanner4en from "assets/banners/main_banner_4_en.webp";
import mainBanner4es from "assets/banners/main_banner_4_es.webp";
import mainBanner4ko from "assets/banners/main_banner_4_ko.webp";
import mainBannerAespa from "assets/banners/main_banner_aespa.png";
import mainbannerSeventeen from "assets/banners/main_banner_seventeen.png";
import mainbannerChart1 from "assets/banners/main_banner_chart_1.png";
import mainbannerChart2 from "assets/banners/main_banner_chart_2.png";
import mainbannerChart3 from "assets/banners/main_banner_chart_3.png";

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    appendNamespaceToMissingKey: true,
    order: [
      "path",
      "querystring",
      "cookie",
      "localStorage",
      "sessionStorage",
      "navigator",
      "htmlTag",
      "subdomain",
    ],
    default: "en",
    lng: `${window.localStorage.getItem("i18nextLng")}`,
    fallbackLng: "en",
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    resources: {
      en: {
        translation: {
          common: {
            agency: "Agency",
            hanteoChart: "Hanteo Chart",
            SeeMore: "See More",
            criteria: "Criteria(KST) : ",
            realTime: "Real-time",
            daily: "Daily",
            weekly: "Weekly",
            monthly: "Monthly",
            yearly: "Yearly",
            worldIndex: "World index",
            physicalRecordIndex: "Physical record index",
            starIndex: "Star index",
            digitalIndex: "Digital index",
            globalIndex: "Authentication index",
            socialIndex: "Social index",
            usChartIndex: "United States Score",
            jpChartIndex: "Japan Score",
            cnChartIndex: "China Score",

            worldIndexShort: "World",
            physicalRecordIndexShort: "Physical record",
            starIndexShort: "Star",
            digitalIndexShort: "Digital",
            globalIndexShort: "Authentication",
            socialIndexShort: "Social",

            artist: "Artist",
            albumInfo: "Album Info",
            musicIndex: "Music Index",
            diff: "Difference",
            salesVolume: "Sales Amount",
            open: "Open",
            close: "Close",
            album: "Album",

            debut: "Debut Year",
            genre: "Genre",
            mGroup: "Male Group",
            wGroup: "Female Group",
            mSolo: "Male Solo",
            wSolo: "Female Solo",
            mixed: "Mixed Group",
            undefined: "Uncategorized",
          },
          headerMobile: {
            notice: "Notice",
            tobereleased: "To Be Released",
            newalbum: "Latest Album",
            family: {
              search: "Certified Families",
              guide: "About Families",
              certificate: "Certification",
            },
            about: "What is Hanteo Chart?",
          },
          headerNav: {
            home: "Home",
            chart: "Chart",
            hallOfFame: "The Hall of Fame",
            family: "Family",
            aboutHanteo: "What is Hanteo Chart?",
          },
          chartNav: {
            world: "World",
            album: "Physical Record",
            global: "Authentication",
            music: "Music",
            sound: "Digital",
            social: "Social",
            star: "Star",
            genre: "Genre",
            media: "Media",
          },
          hallofFameNav: {
            initialChodongRecords: "Initial Chodong Records",
          },
          familyNav: {
            certifiedFamilies: "Certified Families",
            aboutFamilies: "About Families",
            certification: "Certification",
          },
          hmas: {
            title: "Hanteo Music Awards",
            name: "Hanteo Music Awards",
          },
          main: {
            mainbanner1: mainBanner1en,
            mainbanner2: mainBanner2en,
            mainbanner4: mainBanner4,
            mainbannerSeventeen: mainbannerSeventeen,
            mainbanner4Href: "https://awards.hanteo.com/",
            mainbannerSeventeenHref:
              "https://www.hanteonews.com/en/article/chart?fc=57689",
            mainbannerChart1: mainbannerChart1,
            mainbannerChart1Href: "https://awards.hanteo.com/",
            mainbannerChart2: mainbannerChart2,
            mainbannerChart2Href: "https://awards.hanteo.com/",
            mainbannerChart3: mainbannerChart3,
            mainbannerChart3Href:
              "https://awards.hanteo.com/2023/hma2023/?option=event&l=en&m=false",
            todayIsLast: "Today is the final day!",
            currentlySold: "Currently  {{N}} albums.",
            oneDayLeft: "1 day left.",
            nDaysLeft: "{{N}} days left.",
            banner:
              "<strong>Hanteo Chart,</strong> <br/>the world's one-and-only real-time music chart",
            worldChart: "Weekly World Chart Top ",
            realTimeHanteoChart: "Real-time Hanteo Chart",
            physicalRecordChart: "Physical Record Chart",
            digitalChart: "Digital Chart",
            musicChart: "Music Chart",
            authenticationChart: "Authentication Chart",
            starChart: "Star Chart",
            ongoingInitialChodongRecord: "Ongoing Initial Chodong Record",
            recentChodongTopN: "Recent Chodong Record Top ",
            socialChart: "Weekly Social Chart Top ",
            recommendedArtist: "Recommended Artist",
            latestAlbum: "Latest Album",
            weeklyTrotChart: "Weekly Trot Chart Top ",
            weeklyOSTChart: "Weekly OST Chart Top ",
            weeklyPOPChart: "Weekly POP Chart Top ",
            toBeReleased: "To be Released",
            notice: "Notice",
          },
          worldChart: {
            worldChart: "World Chart Beta",
            worldChartTitle: "World Chart",
            worldChartInfo:
              "World Chart collects, analyzes, and announces the K-pop data from all over the world <br/>in real-time based on Hanteo Chart's big data collection technology.",
            tmeUniChartInfo:
              "<br/><br/>Hanteo-World's 'China Chart' is in collaboration with 'TME CHART (Tencent Music Entertainment Chart)'.",
          },
          physicalAlbumChart: {
            physicalAlbumChart: "Physical Album Chart",
            physicalAlbumChartInfo:
              "It is the world's one-and-only real-time music chart. <br/>The album sales from 1,100 stores worldwide are added and <br/>automatically created into the chart.",
          },
          authenticationChart: {
            authenticationChart: "Authentication Chart",
            authenticationChartInfo:
              "It is a new-concept music chart that the global fans build by directly authenticating genuine albums. <br/>Authenticate your albums now!",
          },
          musicChart: {
            musicChart: "Music Chart",
            musicChartInfo:
              "It is a chart that is automatically created based on Physical Record Chart and Digital Chart. <br/>It consists of 70% Physical and 30% Digital.",
          },
          digitalChart: {
            digitalChart: "Digital Chart",
            digitalChartInfo:
              "The digital song data from Korean and international music streaming companies <br/>are analyzed in real-time and automatically created into the chart.",
          },
          socialChart: {
            socialChart: "Social Chart",
            socialChartInfo:
              "It is a real-data chart that collects big data from social media worldwide in real-time, comprehensively analyzes the artists' fandom size and influence, and reflects the core global indicators. <br/>You can intuitively check the social trend of an artist. <br/>(Currently in beta)",
          },
          starChart: {
            starChart: "Star Chart",
            starChartInfo:
              "It is a chart made by fans themselves. <br/>Regardless of the promotion period of the artist, all activities on the 'Whosfan' mobile app become the scores and are reflected on Star Chart.",
          },
          genreChart: {
            genreChart: "Genre Chart",
            genreChartInfo:
              "It is the physical record chart categorized per genre. <br/>You can check the rankings of K-pop, Trot, OST, and Pop genres. <br/>(More genres to be added)",
          },

          footer: {
            companyIntroduction: "Company Introduction",
            partnership: "Partnership",
            copyright: "Copyright ⓒ HANTEO GLOBAL, Inc. All Rights Reserved",
            logoImg: copyright_logo_en,
          },
          ImageGrid: {
            title: "Enjoy K-pop more deeply with related services!",
            ready: "Coming soon",
          },
          initial: {
            title: "The Hall of Fame",
            subtitle:
              "This is the page that recorded the sales amount during the Initial Chodong period. <br/>Check out the brilliant record of K-pop artists!",
            certifiedArtists:
              "Artists who received a Hanteo Initial Chodong Certificate Top ",
            recentChodong: "Recently concluded Initial Chodong Chart Top ",
            physicalChodong: "Physical Record Initial Chodong Chart Top ",
            artistChodong: "Artist Initial Chodong Chart Top ",
            anualChodong: "Initial Chodong Chart by Year Top ",
            maleGroupChodong: "Male Group Initial Chodong Chart Top ",
            femaleGroupChodong: "Female Group Initial Chodong Chart Top ",
            maleSoloChodong: "Male Solo Initial Chodong Chart Top ",
            femaleSoloChodong: "Female Solo Initial Chodong Chart Top ",
          },
          family: {
            searchBannerTitle:
              "Check the certified family stores <br/>of Hanteo Chart!",
            searchBannerText:
              "Make sure to check Hanteo Chart's family code <br/>and the certification mark that's renewed every year.",
            searchInputPlaceholder: "Search with code or family name",
            premiumFamily: "Premium Family",
            goldFamily: "Gold Family",
            silverFamily: "Silver Family",
            bronzeFamily: "Bronze Family",
            familyCode: "Family Code",
            seeStoreCode: "See store codes",
            hanteoCertificate: "Hanteo Chart Reflection Certificate",
            hanteoCertificateInfo:
              "Search the Hanteo Chart Reflection Certificate of Hanteo Chart's family stores!",
            hanteoCertificateSearchPlaceholder:
              "Search the {{n}}-digit certificate code",
            hanteoCertificateSearchOutput:
              "Search result of certificate with code {{searchInput}} ",
            hanteoCertificateIsTrue: "It's a normally issued certificate.",
            hanteoCertificateIsFalse:
              "It can't be found, or it is an abnormal code.",
            familyGuide: "Guide for Hanteo Chart families",
            familyInfo:
              "The stores certified as a Hanteo Chart family can expose the fact <br/>that they are part of Hanteo Chart family depending on the store's format (online/offline) as below.",
            familyOnlineGuide: "Online store guide",
            familySymbol:
              "* Hanteo Chart family's symbol and unique number are provided by store.",
            familyImage:
              "* Use the provided image file, and link it to Hanteo Chart.<br/> <a className='blue' href='https://www.hanteochart.com'>(www.hanteochart.com)</a>",
            required: "Required",
            optional: "Optional",
            familyFooter:
              "Expose the Hanteo Chart family symbol in the footer on the bottom",
            familyHeader:
              "Expose the Hanteo Chart family symbol at the top on the right",
            familyOfflineGuide: "Offline store guide",

            familyEntranceLogo: "Top right of the store's entrance or signage",
            familyWarning:
              "The image above may differ from the actual product provided.",
            familyInStoreLogo:
              "On the counter or the album shelves of the store",
          },
          graph: {
            country: "country",
            comparisonGraph:
              "Comparison of the current release's Initial Chodong Record with the highest record so far",
            nationRanking: "Country ranking",
            musicPie: "Music index change",
            albumSalesRank: "Changes in Record Chart sales amount and ranking ",
            starIndexRank: "Changes in Star Chart index and ranking ",
            chodongBadge: "Hanteo Chart Initial Chodong record badge",
            radar: "Indices by World Chart Category",
            socialIndex: "Social chart index graph",
            albumSalesVolume: "Sales amount graph by album",
            members: "Members",
            authenticationCountry: "Authentication country ranking",
            collectedStores: "Number of stores collected",
            consumberAge: "Age of main consumers",
            consumberGender: "Gender of main consumers",
            initialSales: "Initial Chodong sales amount",
            daily: "Daily",
            cumulative: "Cumulative",
            dailySales: "Daily sales amount",
            cumulativeSales: "Cumulative sales amount",
            currentAlbumSalesVolume:
              "Sales amount of the currently viewing album",
            prevAlbumSalesVolume: "Highest album sales amount previously",
            relatedRankings: "Related rankings",
            basedOn: "Based on",
            chart: "Chart",
            index: "Index",
            rankings: "Rankings",
          },
          detail: {
            rankingBannerTitle1: "{{chart}} Chart {{rank}}st place",
            rankingBannerTitle2: "{{chart}} Chart {{rank}}nd place",
            rankingBannerTitle3: "{{chart}} Chart {{rank}}rd place",
            rankingBannerTitle: "{{chart}} Chart {{rank}}th place",
            toHanteoNews: HanteoNewsEn,
            toWhosFanStore: WhosFanStoreEn,
            toWhosFan: whosFanEn,
          },
          notice: {
            notice: "Notice",
            noticeInfo: "This is Hanteo Chart's notice.",
            title: "Title",
            regDate: "Date",
          },
          toBeReleased: {
            toBeReleased: "To Be Released",
            toBeReleasedInfo: "Check out the albums to be released.",
          },
          newAlbum: {
            newAlbum: "Latest Album",
            newAlbumInfo: "Check out the albums released recently.",
          },
          aboutHanteo: {
            catchText: "K-pop trend in metadata",
            catchTitle:
              "Korea's first music chart, <br/>The world's one-and-only real-time music chart",
            hanteoIs: "Hanteo Chart",
            hanteoHistory:
              "Has grown together with the history of the Korean music industry for over 30 years.<br/>is Korea's leading music chart that is the world's only chart based on real-time sales amount.<br/>is directly synced with over 1,500 record stores worldwide. (The largest scale)<br/>has the public confidence that all global K-pop fans recognize.<br/>provides various charts that shows the world trend of K-pop at a glance.<br/>is continuously advancing for a fair and reliable chart.",
            worldInfo:
              "This chart is created with K-pop data from all over the world collected with Hanteo Chart's technology. Based on this, it newly showcases the chart that represent the world indices of K-pop.",
            authenticationInfo:
              "It's a new-concept music chart that global fans authenticate the genuine products directly. Authenticate now!",
            albumInfo:
              "It's the world's one-and-only real-time music chart. It adds the physical record sales amount from about 1,100 stores worldwide to automatically create into the chart.",
            musicInfo:
              "It's the chart that's automatically created based on Physical Record chart and Digital Chart. It adds the Physical Record (70%) and Digital (30%) charts.",
            soundInfo:
              "It analyzes the digital music data of main digital music streaming services in Korea and overseas in real-time and automatically creates the chart.",
            starInfo:
              "It's a chart directly created by the fans. Regardless of the promotion period of the artists, all the fan activities on the 'Whosfan' mobile app are scored and reflected on the Star Chart.",
            socialInfo:
              "It's a real data chart that collects SNS big data worldwide in real-time to analyze the fandom size and influence of each artist and reflects the result of global key metrics. It intuitively shows the artists' social trend. (Currently in beta)",
            genreInfo:
              "It's a physical record chart that's categorized by genre. It shows the ranking by genre for K-pop, Trot, OST, and Pop. (More genres to be added later)",

            aboutFamilies:
              "Purchase the K-pop albums with confidence from Hanteo Chart's certified family stores!",
            aboutHonors:
              "Check out the historical moments and brilliant records of K-pop artists!",
            webForMore:
              "* You can find more detailed information on the website.",
          },
          mobilePopup: {
            serviceInfo: "This service is supported in the PC version.",
            checkOnPC: "Please check it on your PC!",
            ok: "OK",
          },
          countryChart: {
            global: "Global",
            us: "United States",
            jp: "Japan",
            cn: "China",
          },
          countryChartPopup:
            "The World Chart and Individual Country's chart are charts announced by collecting and analyzing K-pop data from all over the world in real time, based on the big data collection technology of the Hanteo Chart.",
        },
      },
      ko: {
        translation: {
          common: {
            agency: "소속사",
            hanteoChart: "한터차트",
            SeeMore: "더보기",
            criteria: "집계 기준(KST) : ",
            realTime: "실시간",
            daily: "일간",
            weekly: "주간",
            monthly: "월간",
            yearly: "연간",
            worldIndex: "월드 지수",
            physicalRecordIndex: "음반 지수",
            starIndex: "스타 지수",
            digitalIndex: "음원 지수",
            globalIndex: "인증 지수",
            socialIndex: "소셜 지수",
            usChartIndex: "미국 지수",
            jpChartIndex: "일본 지수",
            cnChartIndex: "중국 지수",

            worldIndexShort: "월드 지수",
            physicalRecordIndexShort: "음반 지수",
            starIndexShort: "스타 지수",
            digitalIndexShort: "음원 지수",
            globalIndexShort: "인증 지수",
            socialIndexShort: "소셜 지수",

            artist: "아티스트",
            albumInfo: "앨범 정보",
            musicIndex: "뮤직 지수",
            diff: "차이",
            salesVolume: "판매량",
            open: "열기",
            close: "닫기",
            album: "앨범",
            debut: "데뷔년도",
            genre: "장르",
            mGroup: "남자 그룹",
            wGroup: "여자 그룹",
            mSolo: "남자 솔로",
            wSolo: "여자 솔로",
            mixed: "혼성 그룹",
            undefined: "미정의",
          },
          headerMobile: {
            notice: "공지사항",
            tobereleased: "발매 예정",
            newalbum: "최신 앨범",
            family: {
              search: "인증 패밀리",
              guide: "패밀리 안내",
              certificate: "인증서",
            },
            about: "한터차트는?",
          },
          headerNav: {
            home: " 홈",
            chart: "차트",
            hallOfFame: "명예의 전당",
            family: "패밀리",
            aboutHanteo: "한터차트는?",
          },
          chartNav: {
            world: "월드",
            album: "음반",
            global: "인증",
            music: "뮤직",
            sound: "음원",
            social: "소셜",
            star: "스타",
            genre: "장르별",
            media: "미디어",
          },
          hallofFameNav: {
            initialChodongRecords: "초동 기록",
          },
          familyNav: {
            certifiedFamilies: "인증 패밀리",
            aboutFamilies: "패밀리 안내",
            certification: "인증서",
          },
          hmas: {
            title: "한터뮤직어워즈 2022",
            name: "한터뮤직어워즈 2022",
          },
          main: {
            mainbanner1: mainBanner1ko,
            mainbanner2: mainBanner2ko,
            mainbanner4: mainBanner4,
            mainbannerSeventeen: mainbannerSeventeen,
            mainbanner4Href: "https://awards.hanteo.com/",
            mainbannerSeventeenHref:
              "https://www.hanteonews.com/ko/article/chart?fc=57686",
            mainbannerChart1: mainbannerChart1,
            mainbannerChart1Href: "https://awards.hanteo.com/",
            mainbannerChart2: mainbannerChart2,
            mainbannerChart2Href: "https://awards.hanteo.com/",
            mainbannerChart3: mainbannerChart3,
            mainbannerChart3Href:
              "https://awards.hanteo.com/2023/hma2023/?option=event&l=ko&m=false",
            todayIsLast: "오늘 마감입니다!",
            currentlySold: "현재 {{N}}장",
            oneDayLeft: "1일 남았습니다.",
            nDaysLeft: "{{N}}일 남았습니다. ",
            banner:
              "세계 유일의 실시간 음악차트, <strong>한터차트</strong>" /* Trans */,
            worldChart: "주간 월드차트 Top ",
            realTimeHanteoChart: "실시간 한터차트",
            physicalRecordChart: "음반차트",
            digitalChart: "음원차트",
            musicChart: "뮤직차트",
            authenticationChart: "인증차트",
            starChart: "스타차트",
            ongoingInitialChodongRecord: "진행 중인 초동 기록",
            recentChodongTopN: "최근 초동 기록 Top ",
            socialChart: "주간 소셜차트 Top ",
            recommendedArtist: "추천 아티스트",
            latestAlbum: "최신 음반",
            weeklyTrotChart: "주간 트로트차트 Top ",
            weeklyOSTChart: "주간 OST차트 Top ",
            weeklyPOPChart: "주간 POP차트 Top ",
            toBeReleased: "발매 예정",
            notice: "공지사항",
          },
          worldChart: {
            worldChart: "월드차트 Beta",
            worldChartTitle: "월드차트",
            worldChartInfo:
              "월드차트는 한터차트의 빅데이터 수집 기술로 <br/>전 세계의 케이팝 관련 데이터를 실시간으로 수집하고 분석하여 발표되고 있습니다.<br/>" +
              "각 국가별 판매처들의 실시간 음반 판매량 및 음원 사용량등이 반영되어 주간 단위로 업데이트 됩니다.",
            tmeUniChartInfo:
              "<br/><br/>한터 월드 '중국차트'는 'TME CHART(텐센트 뮤직 엔터테인먼트 차트)'와 함께 합니다.",
          },
          physicalAlbumChart: {
            physicalAlbumChart: "음반차트",
            physicalAlbumChartInfo:
              "세계 유일의 실시간 음악차트입니다. <br/>전 세계 1,100여 판매점의 음반 판매량이 합산되어 차트로 자동 생성됩니다.",
          },
          authenticationChart: {
            authenticationChart: "인증차트",
            authenticationChartInfo:
              "전 세계 팬들이 직접 정품을 인증하여 만들어가는 신개념의 음악차트입니다. <br/>지금 바로 인증해보세요!",
          },
          musicChart: {
            musicChart: "뮤직차트",
            musicChartInfo:
              "음반차트와 음원차트를 기반으로 자동 생성되는 차트입니다. <br/>음반(70%), 음원(30%)의 기준으로 합산됩니다.",
          },
          digitalChart: {
            digitalChart: "음원차트",
            digitalChartInfo:
              "국내외 주요 음원유통사의 음원 데이터가 실시간 분석되어 차트로 자동 생성됩니다.",
          },
          socialChart: {
            socialChart: "소셜차트",
            socialChartInfo:
              "전 세계 SNS의 빅데이터가 실시간으로 수집되어 아티스트의 팬덤 규모와 영향력이 종합적으로 분석되었으며, 글로벌 핵심 지표의 결과가 반영된 리얼 데이터 차트입니다. 아티스트의 소셜 트렌드를 직관적으로 확인할 수 있습니다. <br/>(현재 베타 적용 중)",
          },
          starChart: {
            starChart: "스타차트",
            starChartInfo:
              "팬 여러분이 직접 만들어가는 차트입니다. <br/>아티스트의 활동ㆍ비활동 기간과 관계없이 '후즈팬(Whosfan)' 모바일 앱에서 이루어지는 모든 팬 활동이 점수화되어 스타차트에 반영됩니다.",
          },
          genreChart: {
            genreChart: "장르별 차트",
            genreChartInfo:
              "장르에 의해 분류되어 생성된 음반차트입니다. 케이팝, 트로트, OST, POP의 장르별 순위를 확인할 수 있습니다. <br/>(추후 장르 추가 예정)",
          },

          footer: {
            companyIntroduction: "회사 소개",
            partnership: "제휴/제안 문의",
            copyright:
              "㈜한터글로벌 Copyright ⓒ HANTEO GLOBAL, Inc. All Rights Reserved",
            logoImg: copyright_logo,
          },
          ImageGrid: {
            title: "연관 서비스로 케이팝 더 깊게 즐기기!",
            ready: "준비 중입니다.",
          },
          initial: {
            title: "명예의 전당",
            subtitle:
              "초동 기간 동안의 판매량을 기록한 페이지입니다. <br/>케이팝 아티스트들의 눈부신 기록을 확인하세요!",
            certifiedArtists: "한터 초동 인증패 수상 아티스트",
            recentChodong: "최근 마감 초동차트 Top ",
            physicalChodong: "역대 음반 초동차트 Top ",
            artistChodong: "역대 아티스트 초동차트 Top ",
            anualChodong: "연도별 초동차트 Top ",
            maleGroupChodong: "역대 남성 그룹 초동차트 Top ",
            femaleGroupChodong: "역대 여성 그룹 초동차트 Top ",
            maleSoloChodong: "역대 남성 솔로 초동차트 Top ",
            femaleSoloChodong: "역대 여성 솔로 초동차트 Top ",
          },
          family: {
            searchBannerTitle: "한터차트의 인증된 패밀리 판매점을 확인하세요!",
            searchBannerText:
              "한터차트 패밀리 코드와 연도별로 갱신되는 인증마크를 반드시 확인하고 구매하세요.",
            searchInputPlaceholder: "코드 or 판매점 명으로 검색하기",
            premiumFamily: "프리미엄 패밀리",
            goldFamily: "골드 패밀리",
            silverFamily: "실버 패밀리",
            bronzeFamily: "브론즈 패밀리",
            familyCode: "패밀리 코드",
            seeStoreCode: "매장코드 보기",
            hanteoCertificate: "한터차트 반영 인증서",
            hanteoCertificateInfo:
              "한터차트 패밀리 판매점에서 발행한 한터차트 반영 인증서를 검색해 보세요!",
            hanteoCertificateSearchPlaceholder:
              "인증서 코드 {{n}}자리 검색하기",
            hanteoCertificateSearchOutput:
              "{{searchInput}} 코드 인증서 검색 결과 ",
            hanteoCertificateIsTrue: "정상적으로 발급된 인증서입니다.",
            hanteoCertificateIsFalse: "조회되지 않거나 비정상적인 코드입니다.",
            familyGuide: "한터차트 패밀리를 위한 가이드",
            familyInfo:
              "한터차트 패밀리로 인증된 판매점은 매장 형태(온라인/오프라인)에 따라 <br/>아래와 같이 한터차트 패밀리임을 노출할 수 있습니다.",
            familyOnlineGuide: "온라인 판매점 가이드",
            familySymbol:
              "* 한터차트 패밀리 심볼과 고유번호는 매장별로 제공합니다.",
            familyImage:
              "* 제공된 이미지 파일을 사용하시고, 링크는 한터차트로 연결해 주세요. <a className='blue' href='https://www.hanteochart.com'> (www.hanteochart.com)</a>",
            required: "필수",
            optional: "선택",
            familyFooter: "푸터 하단에 한터차트 패밀리 심볼 노출",
            familyHeader: "상단 메뉴 영역 우측에 한터차트 패밀리 심볼 노출",
            familyOfflineGuide: "오프라인 판매점 가이드",
            familyEntranceLogo: "매장 입구 간판, 또는 도어 우측 상단",
            familyWarning:
              "상기 이미지와 실제 제공되는 제품의 형태가 다를 수 있습니다.",
            familyInStoreLogo: "매장 내 카운터 위, 또는 음반 진열대",
          },
          graph: {
            country: "국가",
            comparisonGraph: "직전 최고 기록 대비 현재 앨범의 초동 기록 비교",
            nationRanking: "국가별 순위",
            musicPie: "뮤직 지수 변화",
            albumSalesRank: "음반차트 판매량 및 순위 변화",
            starIndexRank: "스타차트 지수 및 순위 변화",
            chodongBadge: "한터차트 초동 기록 뱃지",
            radar: "월드차트 부문별 지수",
            socialIndex: "소셜차트 지수 그래프",
            albumSalesVolume: "앨범별 판매량 그래프",
            members: "멤버",
            authenticationCountry: "국가별 인증 순위",
            collectedStores: "집계된 판매처 수",
            consumberAge: "주요 소비 연령",
            consumberGender: "주요 소비 성별",
            initialSales: "초동 판매량",
            daily: "일별",
            cumulative: "누적",
            dailySales: "일별 판매량",
            cumulativeSales: "누적 판매량",
            currentAlbumSalesVolume: "현재 조회중인 앨범 판매량",
            prevAlbumSalesVolume: "직전 최대 앨범 판매량",
            relatedRankings: "연관 순위",
            basedOn: "기준",
            chart: "차트",
            index: "지수",
            rankings: "순위",
          },
          detail: {
            rankingBannerTitle1: "{{chart}}차트 {{rank}}위",
            rankingBannerTitle2: "{{chart}}차트 {{rank}}위",
            rankingBannerTitle3: "{{chart}}차트 {{rank}}위",
            rankingBannerTitle: "{{chart}}차트 {{rank}}위",
            toHanteoNews: HanteoNewsKr,
            toWhosFanStore: WhosFanStoreKr,
            toWhosFan: whosFanKr,
          },
          notice: {
            notice: "공지사항",
            noticeInfo: "한터차트의 공지사항입니다.",
            title: "제목",
            regDate: "작성일",
          },
          toBeReleased: {
            toBeReleased: "발매 예정",
            toBeReleasedInfo: "발매가 예정되어 있는 앨범을 확인하세요.",
          },
          newAlbum: {
            newAlbum: "최신 앨범",
            newAlbumInfo: "최근에 발매된 앨범을 확인하세요.",
          },
          aboutHanteo: {
            catchText: "메타 데이터로 확인하는 케이팝 트렌드",
            catchTitle: "대한민국 최초, <br/>세계 유일의 실시간 음악차트",
            hanteoIs: "한터차트는,",
            hanteoHistory:
              "대한민국 음악산업의 역사와 30년 이상을 함께 성장해 왔습니다. <br/>세계에서 유일하게 <strong>실시간 판매량 기준</strong>으로 하는 대한민국 대표 음악차트입니다.<br/><strong>전 세계 1,500여 개 이상</strong>의 음반 판매점과 직접 연동되어 있습니다. (최대 규모)<br/>글로벌 케이팝 팬이라면 누구나 알고 있는 공신력을 가지고 있습니다.<br/>케이팝의 세계 트렌드를 한눈에 파악할 수 있는 <strong>다양한 차트를 제공</strong>합니다.<br/>공정하고 신뢰있는 차트를 지향하며 <strong>지속적으로 고도화</strong>를 이뤄오고 있습니다.",
            worldInfo:
              "월드차트는 <strong>한터차트의 빅데이터 수집 기술</strong>을 바탕으로 <br /> <strong>전 세계의 케이팝 관련 데이터를 실시간</strong>으로 수집하고 분석하여 발표되는 차트입니다.",
            authenticationInfo:
              "전 세계 팬들이 직접 정품을 <strong>인증</strong>하여 만들어가는 신개념의 음악차트입니다. <br />지금 바로 인증해보세요!",
            albumInfo:
              "세계 유일의 <strong>실시간 음악차트</strong>입니다. <br />전 세계 1,100여 판매점의 음반 판매량이 합산되어 차트로 자동 생성됩니다.",
            musicInfo:
              "음반차트와 음원차트를 기반으로 자동 생성되는 차트입니다. <br /><strong>음반(70%), 음원(30%)</strong>의 기준으로 합산됩니다.",
            soundInfo:
              "국내외 주요 음원유통사의 음원 데이터가 <strong>실시간 분석</strong>되어 차트로 자동 생성됩니다.",
            starInfo:
              "팬 여러분이 직접 만들어가는 차트입니다. 아티스트의 활동ㆍ비활동 기간과 관계없이 <br /><strong>'후즈팬(Whosfan)' 모바일 앱</strong>에서 이루어지는 모든 팬 활동이 점수화되어 스타차트에 반영됩니다.",
            socialInfo:
              "전 세계 SNS의 빅데이터가 실시간으로 수집되어 <strong>아티스트의 팬덤 규모와 영향력이 종합적</strong>으로 분석되었으며, <br/>글로벌 핵심 지표의 결과가 반영된 <strong>리얼 데이터 차트</strong>입니다. <br/>아티스트의 소셜 트렌드를 직관적으로 확인할 수 있습니다. (현재 베타 적용 중)",
            genreInfo:
              "장르에 의해 분류되어 생성된 음반차트입니다. <br/><strong>케이팝, 트로트, OST, POP의 장르별 순위</strong>를 확인할 수 있습니다. (추후 장르 추가 예정)",
            webForMore: "* 웹페이지에서 더 자세한 정보를 확인하실 수 있습니다.",

            aboutFamilies:
              "케이팝 앨범은 한터차트의 인증된 패밀리 판매점에서 믿고 구매하세요!",
            aboutHonors:
              "케이팝 아티스트들의 역사적 순간과 눈부신 기록을 확인하세요!",
          },
          mobilePopup: {
            serviceInfo: "해당 서비스는 PC 버전에서 지원 가능합니다.",
            checkOnPC: "PC에서 확인해 주세요!",
            ok: "확인",
          },
          countryChart: {
            global: "글로벌",
            us: "미국",
            jp: "일본",
            cn: "중국",
          },
          countryChartPopup:
            "월드차트와 각 국가별 차트는 한터차트의 빅데이터 수집 기술을 바탕으로 전 세계의 케이팝 데이터를 실시간으로 수집하고 분석하여 발표되는 차트입니다.",
        },
      },
      es: {
        translation: {
          common: {
            hanteoChart: "Hanteo Chart",
            SeeMore: "Ver más",
            criteria: "Criterios(KST) : ",
            realTime: "Tiempo Real",
            daily: "Diario",
            weekly: "Semanal",
            monthly: "Mensual",
            yearly: "Anual",
            worldIndex: "Indicadores globales",
            physicalRecordIndex: "Indicadores de ventas físicas",
            digitalIndex: "Indicadores digitales",
            starIndex: "Indicadores por Artista",
            globalIndex: "Indicadores de certificación",
            socialIndex: "Indicadores sociales",
            usChartIndex: "United States Score",
            jpChartIndex: "Japan Score",
            cnChartIndex: "China Score",

            worldIndexShort: "Globales",
            physicalRecordIndexShort: "Ventas físicas",
            digitalIndexShort: "Digitales",
            starIndexShort: "Artista",
            globalIndexShort: "Certificación",
            socialIndexShort: "Sociales",

            artist: "Artista",
            albumInfo: "Album Info",
            musicIndex: "Indicadores musicales",
            diff: "Diferencia",
            salesVolume: "Ventas",
            open: "Open",
            close: "Close",
            album: "Álbum",

            debut: "Año de debut",
            genre: "Género",
            mGroup: "Grupo masculino",
            wGroup: "Grupo femenino",
            mSolo: "Solista masculino",
            wSolo: "Solista femenino",
            mixed: "Grupo mixto",
            undefined: "sin categorizar",
          },
          headerMobile: {
            notice: "Aviso",
            tobereleased: "Próximos lanzamientos",
            newalbum: "Últimos lanzamientos",
            family: {
              search: "Familia Certificada",
              guide: "Sobre Familia",
              certificate: "Certificación",
            },
            about: "¿Qué es Hanteo Chart?",
          },
          headerNav: {
            home: " Inicio",
            chart: "Rankings",
            hallOfFame: "Salón de la Fama",
            family: "Familia",
            aboutHanteo: "¿Qué es Hanteo Chart?",
          },
          chartNav: {
            world: "Global",
            album: "Ventas Físicas",
            global: "Certificación",
            music: "Musical",
            sound: "Digital",
            social: "Social",
            star: "Artista",
            genre: "Género",
            media: "Media",
          },
          hallofFameNav: {
            initialChodongRecords: "Ventas Iniciales",
          },
          familyNav: {
            certifiedFamilies: "Familia Certificada",
            aboutFamilies: "Sobre Familia",
            certification: "Certificación",
          },
          hmas: {
            title: "Hanteo Music Awards",
            name: "Hanteo Music Awards",
          },
          main: {
            mainbanner1: mainBanner1es,
            mainbanner2: mainBanner2es,
            mainbanner4: mainBanner4,
            mainbannerSeventeen: mainbannerSeventeen,
            mainbanner4Href: "https://awards.hanteo.com/",
            mainbannerSeventeenHref:
              "https://www.hanteonews.com/es/article/todos?fc=57712",
            mainbannerChart1: mainbannerChart1,
            mainbannerChart1Href: "https://awards.hanteo.com/",
            mainbannerChart2: mainbannerChart2,
            mainbannerChart2Href: "https://awards.hanteo.com/",
            mainbannerChart3: mainbannerChart3,
            mainbannerChart3Href:
              "https://awards.hanteo.com/2023/hma2023/?option=event&l=es&m=false",
            todayIsLast: "¡Último día!",
            currentlySold: "Actualmente {{N}} álbumes.",
            oneDayLeft: "Queda 1 día.",
            nDaysLeft: "Quedan {{N}} días.",
            banner:
              "<strong>Hanteo Chart</strong>, <br/>el único ranking musical en tiempo real del mundo",
            worldChart: "Ranking Global Semanal Top ",
            realTimeHanteoChart: "Rankings Hanteo en tiempo real",
            physicalRecordChart: "Ranking de Ventas Físicas",
            digitalChart: "Ranking Digital",
            musicChart: "Ranking Musical",
            authenticationChart: "Ranking de Certificación",
            starChart: "Ranking por Artista",
            ongoingInitialChodongRecord: "Ventas Iniciales en curso",
            recentChodongTopN: "Ventas Iniciales recientes Top ",
            socialChart: "Ranking Social Semanal Top ",
            recommendedArtist: "Artistas recomendados",
            latestAlbum: "Álbumes recientes",
            weeklyTrotChart: "Ranking Trot Semanal Top ",
            weeklyOSTChart: "Ranking OST Semanal Top ",
            weeklyPOPChart: "Ranking POP Semanal Top ",
            toBeReleased: "Próximos lanzamientos",
            notice: "Aviso",
          },
          worldChart: {
            worldChart: "Ranking Global Beta",
            worldChartTitle: "Ranking Global",
            worldChartInfo:
              "El Ranking Global recopila, analiza y publica en tiempo real los datos sobre K-pop de alrededor del mundo <br />gracias a la tecnología de recolección de macrodatos de Hanteo Chart.",
            tmeUniChartInfo:
              "<br/><br/>'China Chart' de Hanteo-World se realiza en colaboración con 'TME CHART (Tencent Music Entertainment Chart)'.",
          },
          physicalAlbumChart: {
            physicalAlbumChart: "Ranking de Ventas Físicas",
            physicalAlbumChartInfo:
              "Es el único ranking musical en tiempo real del mundo. <br/>Se recopilan las ventas de 1.100 tiendas de todo el mundo, <br/>las cuales automáticamente se ven reflejadas en los rankings.",
          },
          authenticationChart: {
            authenticationChart: "Ranking de Certificación",
            authenticationChartInfo:
              "Es un nuevo concepto de rankings musicales que los fans a nivel mundial <br/>construyen mediante la certificación directa de álbumes completamente genuinos. <br/>¡Certifica tus álbumes ahora!",
          },
          musicChart: {
            musicChart: "Ranking Musical",
            musicChartInfo:
              "Es un ranking que se crea automáticamente en función del Ranking de Ventas Físicas y Ranking Digital. <br/>Consta de un 70% Físico y un 30% Digital.",
          },
          digitalChart: {
            digitalChart: "Ranking Digital",
            digitalChartInfo:
              "Los datos de canciones entregadas por servicios de streaming a nivel mundial<br/> se analizan en tiempo real y se reflejan automáticamente en el ranking.",
          },
          socialChart: {
            socialChart: "Ranking Social",
            socialChartInfo:
              "Es un ranking que recopila macrodatos de las redes sociales a nivel mundial en tiempo real, además de analizar exhaustivamente el tamaño y la influencia del fandom de los artistas, <br/>y reflejar los indicadores globales básicos. Puede comprobar intuitivamente la tendencia social de un artista. <br/>(Actualmente en versión beta)",
          },
          starChart: {
            starChart: "Ranking por Artista",
            starChartInfo:
              "Es un ranking hecho por los propios fans. Independientemente del período de promoción del artista, <br/>todas las actividades en la aplicación móvil 'Whosfan' se convierten en puntajes y se reflejan en el Ranking por Artista.",
          },
          genreChart: {
            genreChart: "Ranking por Género",
            genreChartInfo:
              "Es el Ranking de Ventas Físicas categorizadas por género. <br/>Puedes consultar las clasificaciones de los géneros K-Pop, Trot, OST y Pop. <br/>(Se añadirán más géneros musicales)",
          },

          footer: {
            companyIntroduction: "Presentación de la empresa",
            partnership: "Partnership",
            copyright:
              "Copyright ⓒ HANTEO GLOBAL, Inc. Todos los derechos reservados",
            logoImg: copyright_logo_en,
          },
          ImageGrid: {
            title: "¡Disfruta del K-Pop profundamente con este nuevo servicio!",
            ready: "Próximamente",
          },
          initial: {
            title: "Salón de la Fama",
            subtitle:
              "Esta es la página que registra las ventas durante el período de Ventas Iniciales. <br/>¡Revisa las increíbles cifras que lograron tus artistas K-Pop!",
            certifiedArtists:
              "Artistas galardonados con el Certificado Hanteo Chart Top ",
            recentChodong: "Ranking Ventas Iniciales Recientes Top ",
            physicalChodong: "Ranking Ventas Iniciales Físicas Top ",
            artistChodong: "Ranking Ventas Iniciales por Artista Top ",
            anualChodong: "Ranking Ventas Iniciales por Año Top ",
            maleGroupChodong: "Ranking Ventas Iniciales Grupo Masculino Top ",
            femaleGroupChodong: "Ranking Ventas Iniciales Grupo Femenino Top ",
            maleSoloChodong: "Ranking Ventas Iniciales Solista Masculino Top ",
            femaleSoloChodong: "Ranking Ventas Iniciales Solista Femenino Top ",
          },
          family: {
            searchBannerTitle:
              "¡Revisa las tiendas certificadas <br/>de la familia Hanteo Chart!",
            searchBannerText:
              "Asegúrate de revisar el código de la familia Hanteo Chart y el sello <br/>de certificación que se renueva año a año.",
            searchInputPlaceholder: "Puedes buscar por código o nombre",
            premiumFamily: "Familia Premium",
            goldFamily: "Familia Gold",
            silverFamily: "Familia Plata",
            bronzeFamily: "Familia Bronce",
            familyCode: "Código de Familia",
            seeStoreCode: "Ver código de tienda",
            hanteoCertificate: "Certificado de Reflejo en Hanteo Chart",
            hanteoCertificateInfo:
              "¡Busca el Certificado de Reflejo en Hanteo Chart en las tiendas de la Familia Hanteo Chart!",
            hanteoCertificateSearchPlaceholder:
              "Buscar el código de certificación",
            hanteoCertificateSearchOutput:
              "Resultados de búsqueda con el código de certificación {{searchInput}}",
            hanteoCertificateIsTrue: "Certificado emitido exitosamente.",
            hanteoCertificateIsFalse:
              "No se pudo encontrar o este no es un código genuino.",
            familyGuide: "Guía para Familia Hanteo Chart",
            familyInfo:
              "Las tiendas certificadas como Familia Hanteo Chart pueden exponer <br/>que son parte de la familia de Hanteo Chart dependiendo de su formato (online/física) como a continuación.",
            familyOnlineGuide: "Guía para tiendas online",
            familySymbol:
              "* El símbolo de Familia Hanteo Chart y el número de registro se proporcionan por tienda.",
            familyImage:
              "* Utiliza la imagen disponible y asegúrate de que ésta pueda redireccionar al público a Hanteo Chart.  <a className='blue' href='https://www.hanteochart.com'>(www.hanteochart.com)</a>",
            required: "Requerido",
            optional: "Opcional",
            familyFooter:
              "Debes exponer el símbolo de Familia Hanteo Chart en el footer de la página web.",
            familyHeader:
              "Debes exponer el símbolo de Familia Hanteo Chart en la parte superior derecha de la página web.",
            familyOfflineGuide: "Guía para tiendas físicas",

            familyEntranceLogo:
              "Costado superior derecho de la entrada de la tienda o letrero.",
            familyWarning:
              "La imagen de arriba puede diferir del producto real proporcionado.",
            familyInStoreLogo:
              "Sobre el mesón de atención o en las repisas de la tienda.",
          },
          graph: {
            country: "país",
            comparisonGraph:
              "Comparación entre las Ventas Iniciales del lanzamiento actual con su récord más alto",
            nationRanking: "Ranking por país",
            musicPie: "Cambio de Indicadores Musicales",
            albumSalesRank: "Cambios en Ranking y Ventas Físicas",
            starIndexRank: "Cambios en Ranking y Indicadores",
            chodongBadge: "Insignia de Ventas Iniciales Hanteo Chart",
            radar: "Indicadores según categorías de Ranking Global",
            socialIndex: "Gráfico de Indicadores Ranking Social",
            albumSalesVolume: "Gráfico de ventas por álbum",
            members: "Integrantes",
            authenticationCountry: "Ranking de Certificaciones por país",
            collectedStores: "Tiendas recopiladas",
            consumberAge: "Edad de consumidores",
            consumberGender: "Género de consumidores",
            initialSales: "Registro de Ventas Iniciales",
            daily: "Diario",
            cumulative: "Acumulativo",
            dailySales: "Registro diario de ventas",
            cumulativeSales: "Registro acumulativo de ventas",
            currentAlbumSalesVolume: "Registro de ventas del álbum actual",
            prevAlbumSalesVolume: "Récord anterior en ventas",
            relatedRankings: "Rankings relacionados",
            basedOn: "Criterios",
            chart: "Ranking",
            index: "Indicadores",
            rankings: "Posiciones",
          },
          detail: {
            rankingBannerTitle1: "{{rank}}° Lugar Ranking {{chart}}",
            rankingBannerTitle2: "{{rank}}° Lugar Ranking {{chart}}",
            rankingBannerTitle3: "{{rank}}° Lugar Ranking {{chart}}",
            rankingBannerTitle: "{{rank}}° Lugar Ranking {{chart}}",
            toHanteoNews: HanteoNewsEs,
            toWhosFanStore: WhosFanStoreEs,
            toWhosFan: whosFanEs,
          },
          notice: {
            notice: "Aviso",
            noticeInfo: "Este es un aviso de Hanteo Chart.",
            title: "Tema",
            regDate: "Date",
          },
          toBeReleased: {
            toBeReleased: "Próximos lanzamientos",
            toBeReleasedInfo: "Revisa los próximos álbumes por lanzarse.",
          },
          newAlbum: {
            newAlbum: "Últimos lanzamientos",
            newAlbumInfo: "Revisa los álbumes lanzados recientemente.",
          },
          aboutHanteo: {
            catchText: "Las tendencias K-pop en metadatos",
            catchTitle:
              "El primer ranking de Corea, El único ranking musical en tiempo real del mundo",
            hanteoIs: "Hanteo Chart",
            hanteoHistory:
              "Ha crecido de forma paralela a la industria musical de Corea en los últimos 30 años.<br/>Es el ranking musical líder de Corea y que además es el único a nivel mundial que contabiliza en tiempo real ventas físicas.<br/>Se encuentra sincronizado con las ventas de más de 1.500 tiendas a nivel global. (El más extenso)<br/>Posee el reconocimiento de parte del fandom global del K-pop.<br/>Proporciona distintos rankings que dejan en evidencia las tendencias K-pop en solo un vistazo.<br/>Se encuentra en constante desarrollo para proveer un servicio imparcial y confiable.",
            worldInfo:
              "Este ranking se crea gracias a los datos K-pop a nivel mundial que se recopilan gracias a la tecnología de Hanteo Chart. Basándose en esto, demuestra bajo un nuevo método los indicadores globales del K-pop.",
            authenticationInfo:
              "Es un nuevo concepto de rankings musicales que los fans a nivel mundial construyen mediante la certificación directa de álbumes completamente genuinos. ¡Certifica tus álbumes ahora!",
            albumInfo:
              "Es el único ranking musical en tiempo real del mundo. Se recopilan las ventas de 1.100 tiendas de todo el mundo, las cuales automáticamente se ven reflejadas en los rankings.",
            musicInfo:
              "Es un ranking que se crea automáticamente en función del Ranking de Ventas Físicas y Ranking Digital. Consta de un 70% Físico y un 30% Digital.",
            soundInfo:
              "Este ranking analiza los datos de canciones entregadas por servicios de streaming en Corea y a nivel mundial se analizan en tiempo real y se reflejan automáticamente en el ranking.",
            starInfo:
              "Es un ranking hecho por los propios fans. Independientemente del período de promoción del artista, todas las actividades en la aplicación móvil 'Whosfan' se convierten en puntajes y se reflejan en el Ranking por Artista.",
            socialInfo:
              "Es un ranking que recopila macrodatos de las redes sociales a nivel mundial en tiempo real, además de analizar exhaustivamente el tamaño y la influencia del fandom de los artistas, y reflejar los indicadores globales básicos. Puede comprobar intuitivamente la tendencia social de un artista. (Actualmente en versión beta)",
            genreInfo:
              "Es el Ranking de Ventas Físicas categorizadas por género. Puedes consultar las clasificaciones de los géneros K-Pop, Trot, OST y Pop. (Se añadirán más géneros musicales)",

            aboutFamilies:
              "¡Compra con tranquilidad tus álbumes K-pop en las tiendas de la Familia Hanteo Chart!",
            aboutHonors:
              "¡Revisa las increíbles cifras que lograron tus artistas K-Pop!",
            webForMore:
              "* Puedes encontrar información más detallada en el sitio web.",
          },
          mobilePopup: {
            serviceInfo: "Este servicio es compatible con la versión para PC.",
            checkOnPC: "Por favor, compruébelo en su PC!",
            ok: "OK",
          },
          countryChart: {
            global: "Global",
            us: "United States",
            jp: "Japan",
            cn: "China",
          },
          countryChartPopup:
            "El Ranking Mundial y el Ranking por País son aquellos que se componen al recopilar y analizar datos sobre el K-pop a nivel mundial en tiempo real, utilizando la tecnología de recolección de macrodatos de Hanteo Chart.",
        },
      },
    },
  });

export default i18n;
