import React from "react";
import { useTranslation } from "react-i18next";
import { useMobile } from "MobileContext";
import { NavLink } from "react-router-dom";
import LangSelect from "layout/layoutcomponent/LangSelect";
import "./HeaderNav.scss";
import NavItem from "./NavItem";
import Logo from "assets/logos/header-logo-beta.png";
import { menus } from "./NavMenus";

function HeaderNav() {
  const { t } = useTranslation();
  const isMobile = useMobile();

  if (!isMobile)
    return (
      //web
      <ul className="header-navbar">
        <li className="logo">
          <NavLink to="/">
            <img className="img" src={Logo} alt="" />
          </NavLink>
        </li>
        {menus.map((obj) => {
          if (obj.dep2) {
            return (
              <ul className={obj.className} key={obj.id}>
                <NavItem item={t(obj.item)} path={obj.path} />
                <ul className="depth2">
                  {obj.dep2.map((obj) => {
                    return (
                      <NavItem
                        className={obj.className}
                        key={obj.id}
                        item={t(obj.item)}
                        path={obj.path}
                      />
                    );
                  })}
                </ul>
              </ul>
            );
          }
          return (
            <NavItem
              className={obj.className}
              key={obj.id}
              item={t(obj.item)}
              path={obj.path}
            />
          );
        })}
        <NavItem
          className="about"
          item={t("headerNav.aboutHanteo")}
          path="/about"
        />
        <li className="lang-select-box">
          <LangSelect />
        </li>
      </ul>
    );
  //mobile
  return (
    <ul className="header-navbar">
      <div className="top">
        <li className="logo">
          <NavLink to="/">
            <img className="img" src={Logo} alt="" />
          </NavLink>
        </li>
        <NavItem
          className="about"
          item={t("headerNav.aboutHanteo")}
          path="/about"
        />
      </div>
      <div className="bottom">
        {menus.map((obj) => {
          return (
            <NavItem
              disabled={obj.disabled}
              className={obj.className}
              key={obj.id}
              item={t(obj.item)}
              path={obj.path}
            />
          );
        })}
      </div>
    </ul>
  );
}

export default HeaderNav;
