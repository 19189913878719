import { useMobile } from "MobileContext";
import React, { useEffect, useState } from "react";

const LangSelect = () => {
  const isMobile = useMobile();
  const selectOption = [{ value: "ko" }, { value: "en" }, { value: "es" }];

  const [selectLang, setSelectLang] = useState(
    window.localStorage.getItem("i18nextLng")
  );

  useEffect(() => {
    if (selectLang === "null") {
      window.localStorage.setItem("i18nextLng", "en");
      setSelectLang("en");
    }
  }, [selectLang]);

  const onChangeCallback = (e) => {
    setSelectLang(e.target.value);
    window.localStorage.setItem("i18nextLng", e.target.value);
    window.location.reload(false);
  };

  return (
    <div className={`lang-select-box ${isMobile ? "mo" : ""}`}>
      <select onChange={onChangeCallback} value={selectLang}>
        {selectOption.map((obj) => {
          return (
            <option key={obj.value} value={obj.value}>
              {obj.value}
            </option>
          );
        })}
      </select>
    </div>
  );
};

export default LangSelect;
